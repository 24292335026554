import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../pages/home';
import Register from '../pages/register';
import Login from '../pages/login';
import Orders from '../pages/orders';
import OrdersDetail from '../pages/ordersDetail';
import ManageMeals from '../pages/manageMeals';
import Confirmation from '../pages/confirmation';
import { About } from '../pages/about';
import { PrivateRoute } from '../components/PrivateRoute/privateRoute';
import { Tools } from '../pages/tools';
import { ProgramRecommendation } from '../pages/programRecommendation';
import ProgramResultsPage from '../presentational/ProgramResultsPage';
// import AdminRoute from "./components/Routing/AdminRoute";
import { ToastContainer } from 'react-toastify';
import { OneRepMax } from '../pages/oneRepMax';
import { MacronutrientCalculator } from '../pages/macronutrientCalculator';
import { HomeLanding } from '../pages/homeLanding';
import { AboutApp } from '../pages/aboutApp';

export default class IndexRoute extends Component {
  render() {
    let { sectionsRefs, handleScroll } = this.props;
    return (
      <div className='main-content'>
        <ToastContainer theme='light' />
        <Routes>
          <Route path='/food-ordering' exact element={<Home />} />
          <Route
            path='/register'
            exact
            element={
              <PrivateRoute>
                <Register />
              </PrivateRoute>
            }
          />
          <Route path='/login' exact element={<Login />} />
          <Route
            exact
            path='/orders'
            element={
              <PrivateRoute>
                <Orders />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/orders-detail'
            element={
              <PrivateRoute>
                <OrdersDetail />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/manage-meals'
            element={
              <PrivateRoute>
                <ManageMeals />
              </PrivateRoute>
            }
          />
          <Route path='/tools' element={<Tools />} />
          <Route
            exact
            path='/tools/one-rep-max-calculator'
            element={<OneRepMax />}
          />
          <Route
            exact
            path='/tools/macronutrient-calculator'
            element={<MacronutrientCalculator />}
          />
          <Route
            path='/'
            element={
              <HomeLanding
                sectionsRefs={sectionsRefs}
                handleScroll={handleScroll}
              />
            }
          />
          <Route path='/about' element={<About />} />
          <Route path='/the-app' element={<AboutApp />} />

          <Route exact path='/confirmation/:id' element={<Confirmation />} />
          <Route
            exact
            path='/program-recommendation'
            element={<ProgramRecommendation />}
          />
          <Route
            exact
            path='/program-recommendation/results'
            element={
              <ProgramResultsPage
                sectionsRefs={sectionsRefs}
                handleScroll={handleScroll}
              />
            }
          />
          <Route path='*' element={<p>There's nothing here: 404!</p>} />
        </Routes>
      </div>
    );
  }
}
