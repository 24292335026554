export const questionOptions = {
  gender: {
    name: 'Biological gender',
    options: [
      { name: 'Male', value: 'male' },
      { name: 'Female', value: 'female' },
    ],
  },
  unit: {
    name: 'Unit',
    options: [
      { name: 'Imperial', value: 'imperial' },
      // {name: 'Metric', value: 'metric'}
    ],
  },
  height: {
    name: 'Height',
    options: [
      { name: "3'0", value: "3'0" },
      { name: "3'1", value: "3'1" },
      { name: "3'2", value: "3'2" },
      { name: "3'3", value: "3'3" },
      { name: "3'4", value: "3'4" },
      { name: "3'5", value: "3'5" },
      { name: "3'6", value: "3'6" },
      { name: "3'7", value: "3'7" },
      { name: "3'8", value: "3'8" },
      { name: "3'9", value: "3'9" },
      { name: "3'10", value: "3'10" },
      { name: "3'11", value: "3'11" },
      { name: "4'0", value: "4'0" },
      { name: "4'1", value: "4'1" },
      { name: "4'2", value: "4'2" },
      { name: "4'3", value: "4'3" },
      { name: "4'4", value: "4'4" },
      { name: "4'5", value: "4'5" },
      { name: "4'6", value: "4'6" },
      { name: "4'7", value: "4'7" },
      { name: "4'8", value: "4'8" },
      { name: "4'9", value: "4'9" },
      { name: "4'10", value: "4'10" },
      { name: "4'11", value: "4'11" },
      { name: "5'0", value: "5'0" },
      { name: "5'1", value: "5'1" },
      { name: "5'2", value: "5'2" },
      { name: "5'3", value: "5'3" },
      { name: "5'4", value: "5'4" },
      { name: "5'5", value: "5'5" },
      { name: "5'6", value: "5'6" },
      { name: "5'7", value: "5'7" },
      { name: "5'8", value: "5'8" },
      { name: "5'9", value: "5'9" },
      { name: "5'10", value: "5'10" },
      { name: "5'11", value: "5'11" },
      { name: "6'0", value: "6'0" },
      { name: "6'1", value: "6'1" },
      { name: "6'2", value: "6'2" },
      { name: "6'3", value: "6'3" },
      { name: "6'4", value: "6'4" },
      { name: "6'5", value: "6'5" },
      { name: "6'6", value: "6'6" },
      { name: "6'7", value: "6'7" },
      { name: "6'8", value: "6'8" },
      { name: "6'9", value: "6'9" },
      { name: "6'10", value: "6'10" },
      { name: "6'11", value: "6'11" },
      { name: "7'0", value: "7'0" },
      { name: "7'1", value: "7'1" },
      { name: "7'2", value: "7'2" },
      { name: "7'3", value: "7'3" },
      { name: "7'4", value: "7'4" },
      { name: "7'5", value: "7'5" },
      { name: "7'6", value: "7'6" },
      { name: "7'7", value: "7'7" },
      { name: "7'8", value: "7'8" },
      { name: "7'9", value: "7'9" },
      { name: "7'10", value: "7'10" },
      { name: "7'11", value: "7'11" },
      { name: "8'0", value: "8'0" },
      { name: "8'1", value: "8'1" },
      { name: "8'2", value: "8'2" },
      { name: "8'3", value: "8'3" },
      { name: "8'4", value: "8'4" },
      { name: "8'5", value: "8'5" },
      { name: "8'6", value: "8'6" },
      { name: "8'7", value: "8'7" },
      { name: "8'8", value: "8'8" },
      { name: "8'9", value: "8'9" },
      { name: "8'10", value: "8'10" },
      { name: "8'11", value: "8'11" },
      { name: "9'0", value: "9'0" },
    ],
  },
  activityFactor: {
    name: 'Activity',
    options: [
      { name: 'None - Little to no exercise', value: 1.2 },
      {
        name: 'A little - 1 to 3 hours of sports or exercise per week',
        value: 1.375,
      },
      {
        name: 'Some - 4 to 6 hours of sports or exercise per week',
        value: 1.55,
      },
      {
        name: 'A lot - 7 to 9 hours of sports or exercise per week',
        value: 1.725,
      },
      {
        name: 'Extremely Active - 10+ hours of sports or exercise per week',
        value: 1.9,
      },
      // {name: 'Custom', value: ''}
    ],
  },
  bmrFormula: {
    name: 'BMR Formula',
    options: [
      {
        name: 'Mifflin-St Jeor (Recommended)',
        value: 'mifflin-st-jeor',
        explanation:
          "This formula is the most accurate if you don't know your body fat percentage.",
      },
      {
        name: 'Katch-McArdle',
        value: 'katch-mcardle',
        explanation:
          'This formula is the most accurate if you know your body fat percentage and your body fat is less than 20%. If you are not sure about your body fat, use the Mifflin-St Jeor formula.',
      },
      {
        name: 'Harris-Benedict',
        value: 'harris-benedict',
        explanation:
          'This was one of the first equations used to predict BMR, however, Mifflin-St Jeor is now considered more accurate.',
      },
    ],
  },
  goal: {
    name: 'Goal',
    options: [
      {
        name: 'Rapid Weight Loss ~2lb per week',
        value: 'rapid-weight-loss',
        explanation:
          'This goal is suitable for individuals with significant weight to lose, but may not be ideal for those who are slightly overweight or seeking to achieve a leaner physique.',
      },
      {
        name: 'Moderate Weight Loss ~1lb per week',
        value: 'moderate-weight-loss',
        explanation:
          'This goal is generally suitable for most individuals. It allows for consistent weight loss without significant muscle loss, cravings, or fatigue.',
      },
      {
        name: 'Slow Weight Loss ~0.5lb per week',
        value: 'slow-weight-loss',
        explanation:
          'This goal is well-suited for individuals who are already lean and looking to achieve even greater leanness or for athletes following intense training regimens, such as endurance athletes and high-performance athletes in various sports.',
      },
      {
        name: 'Maintain Weight',
        value: 'maintain-weight',
        explanation:
          "This is a favorable goal if you're content with your current body composition or if you desire to take a break from dieting.",
      },
      {
        name: 'Slow Weight Gain ~0.5lb per week',
        value: 'slow-weight-gain',
        explanation:
          'This is an ideal goal for advanced weightlifters aiming to optimize muscle gain and performance while minimizing body fat gain.',
      },
      {
        name: 'Moderate Weight Gain ~1lb per week',
        value: 'moderate-weight-gain',
        explanation:
          'This is an excellent goal for intermediate weightlifters who seek to maximize muscle gain and performance while minimizing body fat gain.',
      },
      {
        name: 'Rapid Weight Gain ~2lb per week',
        value: 'rapid-weight-gain',
        explanation:
          'This is an ideal goal for beginner weightlifters aiming to optimize muscle gain and performance while minimizing body fat gain.',
      },
    ],
  },
};
