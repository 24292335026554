import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AlvaroFitnessGradient from '../../assets/images/af-logo.png';
import { Drawer } from 'antd';
import { handleDrawerMenu } from '../../ducks/Global/actions';
import { MdOutlineKeyboardArrowRight, MdOutlineClose } from 'react-icons/md';

export const DrawerMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDrawerOpen = useSelector((state) => state.global.isDrawerOpen);

  let navLinks = [
    {
      key: 1,
      name: 'Coaching',
      path: '/',
    },
    {
      key: 2,
      name: 'Nutrition Quiz',
      path: '/tools/macronutrient-calculator',
    },
    {
      key: 3,
      name: 'The App',
      path: '/the-app',
    },
    {
      key: 4,
      name: 'About Alvaro',
      path: '/about',
    },
  ];

  return (
    <Drawer
      open={isDrawerOpen}
      onClose={() => dispatch(handleDrawerMenu(false))}
      width={'80%'}
      headerStyle={{
        minHeight: '80px',
        display: 'grid',
        justifyContent: 'flex-end',
        padding: '0px 15px',
      }}
      closeIcon={<MdOutlineClose style={{ fontSize: '24px' }} />}
    >
      <div>
        {navLinks.map((link) => (
          <div
            onClick={() => {
              navigate(link.path);
              dispatch(handleDrawerMenu(false));
            }}
            className='nav-link flex space-between'
            style={{ padding: '10px' }}
          >
            <p>{link.name}</p>
            <MdOutlineKeyboardArrowRight style={{ fontSize: '24px' }} />
          </div>
        ))}
      </div>
    </Drawer>
  );
};
