import React from 'react';
import { Button } from 'antd';
import { FaCheckCircle } from 'react-icons/fa';
import { starRating } from '../../helpers/utils';
import GoogleIcon from '../../assets/images/GoogleIcon.png';

export const LandingHeader = (props) => {
  const {
    color,
    title,
    subtitle,
    btnAction,
    image,
    btnText,
    bulletPoints,
    textOverTitle,
  } = props;
  return (
    <div className='header'>
      {/* <img src={image} alt='Background' />
      <div className='overlay'></div> */}
      <div
        className='header-content'
        style={{ alignContent: image ? 'flex-start' : '' }}
      >
        <div
          className={`header-container ${
            !image ? 'align-center' : 'width-for-img'
          }`}
        >
          <div className='header-container-title'>
            {textOverTitle && (
              <p className='text-gradient eyebrow-text bold mb-10'>
                {textOverTitle}
              </p>
            )}
            <p className={`title program-title ${!image ? 'text-center' : ''}`}>
              {title}
            </p>
          </div>

          {subtitle && (
            <div style={{ marginTop: '-10px' }}>
              <p className='big-subtitle' style={{ maxWidth: '450px' }}>
                {subtitle}
              </p>
            </div>
          )}

          <div className={`${!image ? 'grid justify-center' : ''}`}>
            <Button
              className='landing-button mb-30'
              onClick={btnAction}
              type='primary'
            >
              {btnText}
            </Button>
            {bulletPoints && (
              <div
                style={{
                  background: '#f0f0f0',
                  padding: '20px',
                  borderRadius: '20px',
                }}
              >
                {bulletPoints.map((b) => (
                  <div className='grid-bullet-point'>
                    <FaCheckCircle
                      className='mr-10'
                      style={{ color: '#2761F1', marginTop: '5px' }}
                    />
                    <p className='subtitle' style={{ fontSize: '16qpx' }}>
                      {b}
                    </p>
                  </div>
                ))}
              </div>
            )}
            <div className='header-reviews'>
              <div className='flex center-aligned'>
                <img
                  className='mr-10'
                  style={{ height: '50px' }}
                  src={GoogleIcon}
                  alt='Google'
                  loading='lazy'
                />
                <div>
                  {starRating(5)}
                  <p className='subtitle' style={{ textAlign: 'center' }}>
                    5.0 Reviews
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {image ? (
          <div className='img'>
            <img src={image} alt={'Alvaro'} loading='lazy' />
          </div>
        ) : null}
      </div>
    </div>
  );
};
