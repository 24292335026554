import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Input, Popover, Select } from 'antd';
import MacronutrientIcon from '../assets/images/fruit.svg';
import OneRepMaxIcon from '../assets/images/weightlifting.svg';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { feetToCentimeters } from '../helpers/utils';
import { MacronutrientCalculatorComponent } from '../components/MacroNutrientCalculatorComponent';
import { questionOptions } from '../data/questionOptions';
import '../index.css';

const { Option } = Select;

export const MacronutrientCalculator = (props) => {
  const [showResult, handleShowResult] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [percentageError, setPercentageError] = useState(false);
  const [results, setResults] = useState({
    bmr: 0,
    maintenanceCalories: 0,
    calories: 0,
    protein: 0,
    carbs: 0,
    fat: 0,
  });
  const [macronutrientAnswers, setMacronutrientAnswers] = useState({
    unit: 'imperial',
    gender: 'male',
    weight: 180,
    age: 35,
    height: "5'10",
    bodyFatPercentage: 15,
    activityFactor: 1.55,
    bmrFormula: 'mifflin-st-jeor',
    goal: 'moderate-weight-loss',
    macroPreference: 'balanced',
    isCustomMacros: false,
    customMacros: { protein: 35, carbs: 35, fat: 30 },
  });
  const [activeTool, setActiveTool] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/tools/macronutrient-calculator') {
      setActiveTool({
        key: 1,
        name: 'Macronutrient Calculator',
        cardImg: MacronutrientIcon,
        cardSubtitle:
          'Get the perfect amount of calories, protein, carbs, and fat you should be eating every day.',
        active: true,
        about:
          'Discover your ideal macronutrient intake for building muscle, losing fat, and increasing strength with the Alvaro Fitness Calculator. Determine how much protein, carbs, and fats you should eat every day to achieve your fitness goals.',
      });
    } else if (location.pathname === '/tools/one-rep-max-calculator') {
      setActiveTool({
        key: 2,
        name: 'One Rep Max Calculator',
        cardImg: OneRepMaxIcon,
        cardSubtitle:
          'Calculate your one-repetition maximum (1RM) to optimize your strength training.',
        active: false,
        about:
          "Enhance your strength training regimen with the One Rep Max Calculator. Estimate your one-repetition maximum for various exercises, allowing you to customize your workout intensity and track your progress over time. Whether you're a beginner or an experienced lifter, this tool can help you reach your strength goals.",
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    const bmr = calculateBMR(macronutrientAnswers);

    setResults((prevResults) => ({
      ...prevResults,
      bmr: Math.round(bmr),
    }));
  }, [macronutrientAnswers]);

  useEffect(() => {
    calculateCaloriesAndMacros(results.bmr);
  }, [macronutrientAnswers, results.bmr]);

  // const handleMeasurementSystem = (e) => {
  //   setMeasurementSystem({ unit: e.target.value });
  // };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top when results are shown
  }, [showResult]);

  const handleDropdownValue = (field, value) => {
    setMacronutrientAnswers((prevAnswers) => ({
      ...prevAnswers,
      [field]: value,
    }));
  };

  const renderDropdown = (options, label, name) => {
    let selectedOption = options.filter(
      (option) => option.value === macronutrientAnswers[name]
    )[0];

    return (
      <div className='mb-20'>
        {label === 'Formula' ? (
          <div className='flex align-center'>
            <p className='label'>{label}</p>
            <Popover
              trigger='click'
              content={
                <p
                  style={{ fontSize: '14px', width: '100%', maxWidth: '100px' }}
                >
                  {selectedOption.explanation}
                </p>
              }
            >
              <QuestionCircleOutlined
                style={{ fontSize: '10px', margin: '0 0 5px 3px' }}
              />
            </Popover>
          </div>
        ) : (
          <p className='label'>{label}</p>
        )}
        <Select
          onSelect={(value) => handleDropdownValue(name, value)}
          value={macronutrientAnswers[name]}
          style={{ width: '100%' }}
          dropdownStyle={{
            borderRadius: '10px',
          }}
        >
          {options.map((option, index) => (
            <Option key={index} value={option.value}>
              {option.name}
            </Option>
          ))}
        </Select>
        {name === 'goal' &&
          options.map(
            (option) =>
              option.value === macronutrientAnswers[name] && (
                <p
                  className='subtitle-text'
                  style={{ margin: '10px 0 0 10px' }}
                >
                  {option.explanation}
                </p>
              )
          )}
      </div>
    );
  };

  const renderInputField = (label, name) => {
    return (
      <div className='input-container mb-20'>
        <p className='label'>{label}</p>
        <Input
          onChange={(e) => handleDropdownValue(name, e.target.value)}
          type='number'
          value={macronutrientAnswers[name]}
        />
      </div>
    );
  };

  const calculateBMR = (macroAnswers, callback) => {
    let feet, inches;
    if (macroAnswers.height) {
      [feet, inches] = macroAnswers.height.split("'");
      feet = Number(feet);
      inches = Number(inches);
    }
    let bmrFormula = macroAnswers.bmrFormula;
    let weight =
      macroAnswers.unit === 'imperial'
        ? Number(macroAnswers.weight) * 0.453592
        : macroAnswers.weight;
    let height =
      macroAnswers.unit === 'imperial'
        ? feetToCentimeters(feet, inches)
        : macroAnswers.height;
    let age = Number(macroAnswers.age);
    let lbm = weight * (1 - macroAnswers.bodyFatPercentage / 100);
    let bmr = 0;

    if (macroAnswers.gender === 'male') {
      if (bmrFormula === 'mifflin-st-jeor') {
        // Mifflin-St Jeor (Recommended):
        bmr = 10 * weight + 6.25 * height - 5 * age + 5;
      } else if (bmrFormula === 'harris-benedict') {
        // Harris-Benedict:
        bmr = 88.362 + 13.397 * weight + 4.799 * height - 5.677 * age;
      } else {
        // Katch-McArdle:
        bmr = 370 + 21.6 * lbm;
      }
    } else if (macroAnswers.gender === 'female') {
      if (bmrFormula === 'mifflin-st-jeor') {
        // Mifflin-St Jeor (Recommended):
        bmr = 10 * weight + 6.25 * height - 5 * age - 161;
      } else if (bmrFormula === 'harris-benedict') {
        // Harris-Benedict:
        bmr = 447.593 + 9.247 * weight + 3.098 * height - 4.33 * age;
      } else {
        // Katch-McArdle:
        bmr = 370 + 21.6 * lbm;
      }
    }

    return bmr;
  };

  const calculateCaloriesAndMacros = (bmr, callback) => {
    let activityFactor = macronutrientAnswers.activityFactor;
    let macroPreference = macronutrientAnswers.macroPreference;
    let goal = macronutrientAnswers.goal;
    let customMacros = macronutrientAnswers.customMacros;
    let protein = 0;
    let carbs = 0;
    let fat = 0;
    let calories = 0;
    let maintenanceCalories = bmr * activityFactor;

    if (goal === 'rapid-weight-loss') {
      calories = maintenanceCalories - 1000;
    } else if (goal === 'moderate-weight-loss') {
      calories = maintenanceCalories - 500;
    } else if (goal === 'slow-weight-loss') {
      calories = maintenanceCalories - 250;
    } else if (goal === 'maintain-weight') {
      calories = maintenanceCalories;
    } else if (goal === 'slow-weight-gain') {
      calories = maintenanceCalories + 250;
    } else if (goal === 'moderate-weight-gain') {
      calories = maintenanceCalories + 500;
    } else if (goal === 'rapid-weight-gain') {
      calories = maintenanceCalories + 1000;
    }

    // Calculate macronutrient ratios based on the selected goal
    switch (macroPreference) {
      case 'balanced':
        protein = (0.35 * calories) / 4;
        carbs = (0.35 * calories) / 4;
        fat = (0.3 * calories) / 9;
        break;
      case 'low-carb':
        protein = (0.4 * calories) / 4;
        carbs = (0.2 * calories) / 4;
        fat = (0.4 * calories) / 9;
        break;
      case 'high-carb':
        protein = (0.3 * calories) / 4;
        carbs = (0.5 * calories) / 4;
        fat = (0.2 * calories) / 9;
        break;
      case 'high-protein':
        protein = (0.4 * calories) / 4;
        carbs = (0.3 * calories) / 4;
        fat = (0.3 * calories) / 9;
        break;
      case 'ketogenic':
        protein = (0.4 * calories) / 4;
        carbs = (0.1 * calories) / 4;
        fat = (0.5 * calories) / 9;
        break;
      case 'custom':
        protein = ((customMacros.protein / 100) * calories) / 4;
        carbs = ((customMacros.carbs / 100) * calories) / 4;
        fat = ((customMacros.fat / 100) * calories) / 9;
        break;
      default:
        // If the goal is not recognized, use balanced as the default option
        protein = (0.35 * calories) / 4;
        carbs = (0.35 * calories) / 4;
        fat = (0.3 * calories) / 9;
    }

    setResults((prevResults) => ({
      ...prevResults,
      calories: Math.round(calories),
      maintenanceCalories: Math.round(maintenanceCalories),
      protein: Math.round(protein),
      carbs: Math.round(carbs),
      fat: Math.round(fat),
    }));

    // this.setState(
    //   {
    //     results: {
    //       ...this.state.results,
    //       calories: Math.round(calories),
    //       maintenanceCalories: Math.round(maintenanceCalories),
    //       protein: Math.round(protein),
    //       carbs: Math.round(carbs),
    //       fat: Math.round(fat),
    //     },
    //   },
    //   () => {
    //     // After calories and macronutrients are updated in state, execute the callback function if provided
    //     if (typeof callback === 'function') {
    //       callback();
    //     }
    //   }
    // );
  };

  const setMacroPreference = (macroPreference) => {
    setMacronutrientAnswers((prevAnswers) => ({
      ...prevAnswers,
      macroPreference,
      isCustomMacros: macroPreference === 'custom',
    }));
  };

  const setCustomMacros = (macroType, percentage) => {
    const { customMacros } = macronutrientAnswers;

    let updatedMacros = { ...customMacros };
    updatedMacros[macroType] = percentage;

    // Calculate the remaining macro percentage
    const remainingPercentage =
      100 - (updatedMacros.protein + updatedMacros.carbs + updatedMacros.fat);

    setMacronutrientAnswers((prevAnswers) => ({
      ...prevAnswers,
      customMacros: updatedMacros,
    }));

    const percentageError = remainingPercentage !== 0;
    setPercentageError(percentageError);
  };

  const showResults = () => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
      handleShowResult(true);
    }, 2000);
  };

  return (
    <>
      <div className='tool-heading-container landing-page'>
        <div className={!showResult ? 'tool-heading-content' : ''}>
          {!showResult && (
            <div>
              <h1 className='title'>Eliminate Nutrition Guesswork For Good</h1>
              <p>
                Discover your precise nutritional needs and get a personalized
                hand portion serving guide!
              </p>
            </div>
          )}
          <div className={activeTool ? 'tools-page' : ''}>
            <div id='widgetContainer' className='tool'>
              <MacronutrientCalculatorComponent
                {...props}
                questionOptions={questionOptions}
                renderDropdown={renderDropdown}
                renderInputField={renderInputField}
                macronutrientAnswers={macronutrientAnswers}
                setMacroPreference={setMacroPreference}
                results={results}
                setCustomMacros={setCustomMacros}
                percentageError={percentageError}
                showResult={showResult}
                isLoading={isLoading}
                showResults={showResults}
                handleShowResult={handleShowResult}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
