import React, { useEffect, useState } from 'react';
import NutritionPlan from '../../assets/images/NutritionPlan.png';
import WorkoutPlan from '../../assets/images/WorkoutPlan.png';
import SmartShoppingList from '../../assets/images/SmartShoppingList.png';
import AlternativeFoods from '../../assets/images/AlternativeFoods.png';
import ExerciseHistory from '../../assets/images/ExerciseHistory.png';
import Recipes from '../../assets/images/Recipes.png';
import Mockup from '../../assets/images/Mockup-min.png';
import Pritesh from '../../assets/images/PriteshFront.png';
import Corey from '../../assets/images/CoreyBack.png';
import Fide from '../../assets/images/FideFront.png';
import TelemundoLogo from '../../assets/images/TelemundoLogo.png';
import { faqData } from '../../data/faq';
import { renderFaq } from '../../components/Faq';
import { GoogleReviews } from '../../components/GoogleReviews/index';
import { useSelector } from 'react-redux';
import { openReviewModal } from '../../ducks/Global/actions';
import { Button } from 'antd';
import { AiOutlineCheck } from 'react-icons/ai';
import { CountdownTimer } from '../../components/CountDownTimer';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { ReviewModal } from '../../components/GoogleReviews/reviewModal';
import { LandingHeader } from '../../components/LandingPage/LandingHeader';
import './index.css';

const isProduction = process.env.NODE_ENV === 'production';

function ProgramResultsPage({ sectionsRefs, handleScroll }) {
  const location = useLocation();
  const [isTimerExpired, setTimerExpired] = useState(false);
  const { selectedRecommendation, numberOfWorkouts } = location.state || {};
  const isOpen = useSelector((state) => state.global.isOpen);
  const review = useSelector((state) => state.global.review);

  let expiryTimestamp = localStorage.getItem('expiryTimestamp');

  if (!expiryTimestamp) {
    const currentTime = Date.now();
    expiryTimestamp = currentTime + 24 * 60 * 60 * 1000; // 24 hours in milliseconds
    localStorage.setItem('expiryTimestamp', expiryTimestamp.toString());
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isProduction) {
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
    }
  }, []);

  useEffect(() => {
    const currentTime = Date.now();
    if (expiryTimestamp <= currentTime) {
      setTimerExpired(true);
    }
  }, []);

  const handleStripeCheckoutClick = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: 'Stripe Checkout Button',
    });

    const productUrl = isTimerExpired
      ? 'https://billing.alvarofitness.com/b/00g9AOcjr4AeaNG009'
      : 'https://billing.alvarofitness.com/b/9AQ5ky3MVfeS3le008';

    window.open(productUrl, '_blank');
  };

  const renderNumberOfWorkouts = () => {
    return (
      <div className='section' style={{ background: colorBasedOnGender }}>
        <div className='number-of-workouts-container'>
          <p style={{ fontSize: '100px' }}>💪</p>
          <div className='text-section'>
            <p className='title mb-10'>
              {numberOfWorkouts || 924} Workouts Completed
            </p>
            <p className='paragraph subtitle'>{`Join the movement. My community has already completed a total of ${
              numberOfWorkouts || 924
            } and counting!`}</p>
          </div>
        </div>
      </div>
    );
  };

  const whatYouGet = [
    {
      key: 1,
      img: WorkoutPlan,
      title: 'Science Backed Workouts & Logging ',
      subtitle:
        'Get detailed strength and cardio workouts for your goals, abilities, and preferences. Log your sets, reps, and get real time feedback on exercises compared to previous history.',
    },
    {
      key: 2,
      img: NutritionPlan,
      title: 'Personalized Nutrition',
      subtitle:
        'No one-size-fits-all plans here. Your nutrition plan is completely personalized based on your specific macro and calorie needs, diet type, age, gender, weight, lifestyle, and more.',
    },
    {
      key: 3,
      img: AlternativeFoods,
      title: 'Alternative Foods',
      subtitle:
        "Out of an ingredient on your plan? I've got you covered! The alternative foods feature allows you to switch foods based on your preferences and what you have available.",
    },
    {
      key: 4,
      img: SmartShoppingList,
      title: 'Smart Shopping Lists',
      subtitle:
        'Just select the days you’d like to shop for and items and quantities will be calculated and added to your list automatically! Instantly know exactly what and how much to shop for.',
    },
    {
      key: 5,
      img: Recipes,
      title: 'Delicious Recipes',
      subtitle:
        'Access thousands of recipes and apply advanced filters such as calorie range, diet type, allergies, and more.',
    },
    {
      key: 6,
      img: ExerciseHistory,
      title: 'Exercise History Tracking',
      subtitle:
        'Check your history for each exercise and get valuable information regarding your performance for each exercise.',
    },
  ];

  const howItWorks = [
    {
      key: 1,
      icon: WorkoutPlan,
      title: 'Sign Up',
      subtitle:
        "After signing up for a program, you'll receive an email to complete your assessment. This will allow me to create the perfect plan for you based on your goals.",
    },
    {
      key: 2,
      icon: AlternativeFoods,
      title: 'Access Your Dashboard',
      subtitle:
        "In your dashboard, you'll have the option to select your food preferences and set what foods you prefer to avoid in your plan.",
    },
    {
      key: 3,
      icon: AlternativeFoods,
      title: 'Get Ready To Start',
      subtitle:
        'While I craft your personalized fitness program, take some time to explore the Learn section on your dashboard for valuable insights and tips on optimizing your fitness journey.',
    },
    {
      key: 4,
      icon: AlternativeFoods,
      title: 'Start Your Transformation',
      subtitle:
        'Once your personalized program is ready, you can start your transformation journey. Access your workout and nutrition plans, and get ready to transform.',
    },
  ];

  const pricingCardList = [
    'Personalized Nutrition Plan',
    'Science Based Workout Plans',
    'Workout Logging',
    'Exercise History Data',
    'Exercise Videos (Coming soon)',
    "Access To 1000's Of Recipes",
    'Smart Shopping List Generator',
    'Alternative Foods Functionality',
    'Advanced Nutrition Tools',
    'Integrate With Fitbit And Withings ',
    'Private Support & Accoutability Group',
    '30-Day Money Back Guaranteed',
  ];

  const benefits = [
    {
      icon: '💪',
      title: 'Tailored Workouts and Nutrition',
      subtitle:
        "Upon sign up, I will tailor a personalized workout and nutrition plan based on your goals and preferences. It's not one-size-fits-all; it's customized for you.",
    },
    {
      icon: '📈',
      title: 'Exercise History Tracking',
      subtitle:
        'Beyond simply monitoring your progress, this tool empowers you to hold yourself accountable and push yourself for more.',
    },
    {
      icon: '🛒',
      title: 'Smart Shopping List',
      subtitle:
        'The smart shopping list generator calculates items and quantities in your plan, saving you time and effort. No more guesswork.',
    },
    {
      icon: '🍽️',
      title: 'Alternative Foods',
      subtitle:
        'Every food item in your plan includes alternative options, enabling you to seamlessly swap for your preferred choice.',
    },
    {
      icon: '🥘',
      title: 'Thousands of Recipes',
      subtitle:
        "Craving a recipe over your scheduled meal? Easily get recommended recipes matching your meal's nutritional profile with just a simple click.",
    },
    {
      icon: '📸',
      title: 'Progress Picture Comparison',
      subtitle:
        'Track and compare your physical transformation with the easy-to-use progress picture feature.',
    },
    {
      icon: '📚',
      title: 'Access To Workout, Nutrition, and Lifestyle Guides',
      subtitle:
        'Continuously expand your knowledge and skills with my ever-growing collection of comprehensive workout, nutrition, and lifestyle guides.',
    },
    {
      icon: '👥',
      title: 'Private Support & Accountability Group',
      subtitle:
        'Connect with a dedicated community and your coach to keep you on track until the end of your program and beyond.',
    },
    {
      icon: '🔗',
      title: 'Integrations',
      subtitle:
        'Seamlessly sync with Fitbit for advanced food tracking and Withings for accurate body composition data. Get of automatic updates for your weight, body fat, and nutritional needs as your body changes.',
    },
    {
      icon: '🚀',
      title: 'New Feature Releases',
      subtitle:
        "I'm dedicated to innovation. Get ready for constant releases of new features and improvements, ensuring your fitness journey stays fresh and exciting.",
    },
  ];

  const colorBasedOnGender =
    selectedRecommendation.gender === 'male' ? '#DCE0FF' : '#FFD9D9';

  const stepNumberStyle = {
    background: colorBasedOnGender,
    borderRadius: '10px',
    width: '60px',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <>
      <ReviewModal
        isOpen={isOpen}
        openReviewModal={openReviewModal}
        colorBasedOnGender={colorBasedOnGender}
        review={review}
      />
      <div className='landing-page'>
        {selectedRecommendation ? (
          <LandingHeader
            color={colorBasedOnGender}
            title={selectedRecommendation.program}
            subtitle={selectedRecommendation.description}
            btnAction={() => handleScroll(sectionsRefs['#home-section-1'])}
            image={Mockup}
            btnText={'Learn More'}
            textOverTitle={''}
          />
        ) : null}
        <div className='featured-banner'>
          <div className='featured-logo'>
            <p className='subtitle bold'>Featured on</p>
            <img
              src={TelemundoLogo}
              alt='Telemundo'
              style={{ height: '60px' }}
            />
          </div>
        </div>

        <div ref={sectionsRefs['#home-section-1']} className='section gray'>
          <div className='content' style={{ paddingTop: '0px' }}>
            <div className='grid grid justify-center mb-50'>
              <p className='title mb-20' style={{ textAlign: 'center' }}>
                A Fitness Program at{' '}
                <span className='text-gradient underlined-title'>
                  Another Level.
                </span>
              </p>
              <p
                className='big-subtitle'
                style={{
                  width: '100%',
                  maxWidth: '600px',
                  textAlign: 'center',
                }}
              >
                Easy To Follow · Enjoyable · Transformative
              </p>
            </div>
            <div className='container'>
              <div className='benefits'>
                {benefits.map((benefit) => (
                  <div className='global-card flex flex-center-aligned'>
                    <p style={{ fontSize: '50px', marginRight: '20px' }}>
                      {benefit.icon}
                    </p>
                    <div className='benefits-info'>
                      <p className='small-title'>{benefit.title}</p>
                      <p className='subtitle'>{benefit.subtitle}</p>
                    </div>
                  </div>
                ))}
              </div>
              <Button
                className='landing-button'
                onClick={() => handleStripeCheckoutClick()}
                type='primary'
              >
                Get Started Now!
              </Button>
            </div>
          </div>
        </div>
        <div ref={sectionsRefs['#home-section-2']} className='section white'>
          <div className='content'>
            <p className='title mb-50' style={{ textAlign: 'center' }}>
              What Clients{' '}
              <span className='text-gradient underlined-title'>Are Saying</span>
            </p>
            <div className='container'>
              <GoogleReviews color={colorBasedOnGender} />
            </div>
          </div>
        </div>
        {renderNumberOfWorkouts()}
        <div ref={sectionsRefs['#home-section-3']} className='section white'>
          <div className='content'>
            <div className='grid grid justify-center mb-50'>
              <p className='title mb-20' style={{ textAlign: 'center' }}>
                Your{' '}
                <span className='text-gradient underlined-title'>Program</span>
              </p>
              <p
                className='big-subtitle'
                style={{
                  width: '100%',
                  maxWidth: '600px',
                  textAlign: 'center',
                }}
              >
                Upon signing up, I will review your assessment and begin
                crafting a personalized workout and nutrition plan tailored for
                you.
              </p>
            </div>
            <div className='container'>
              {whatYouGet.map((item) => (
                <div className='what-you-get-item'>
                  <img className='mb-30' src={item.img} alt='Program Feature' />
                  <div className='text mt-20' style={{ textAlign: 'center' }}>
                    <p className='small-title mb-10'>{item.title}</p>
                    <p className='subtitle'>{item.subtitle}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div ref={sectionsRefs['#home-section-4']} className='section gray'>
          <div className='content'>
            <p className='title mb-50' style={{ textAlign: 'center' }}>
              How It{' '}
              <span className='text-gradient underlined-title'>Works</span>
            </p>
            <div className='container' style={{ gridGap: '20px' }}>
              {howItWorks.map((step, i) => (
                <div className='how-it-works-card'>
                  <div style={stepNumberStyle} className='title mb-20'>
                    {i + 1}
                  </div>
                  <h3 className='small-title mb-10'>{step.title}</h3>
                  <p className='subtitle'>{step.subtitle}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div ref={sectionsRefs['#home-section-5']} className='section white'>
          <div className='content' style={{ textAlign: 'center' }}>
            <div className='grid grid justify-center mb-50'>
              <p className='title mb-20' style={{ textAlign: 'center' }}>
                Success{' '}
                <span className='text-gradient underlined-title'> Stories</span>
              </p>
              <p
                className='big-subtitle mb-50'
                style={{
                  width: '100%',
                  maxWidth: '600px',
                  textAlign: 'center',
                }}
              >
                You Are One Step Away From Being Next
              </p>
            </div>
            <div className='grid'>
              <div className='transformation-container'>
                <img src={Fide} className='transformation-img' alt='Client 1' />
                <img
                  src={Corey}
                  className='transformation-img'
                  alt='Client 2'
                />
                <img
                  src={Pritesh}
                  className='transformation-img'
                  alt='Client 3'
                />
              </div>
            </div>
            <Button
              className='landing-button mt-50'
              onClick={() => handleStripeCheckoutClick()}
              type='primary'
            >
              Start Your Transformation Today!
            </Button>
          </div>
        </div>
        <div className='section gray'>
          <div className='text-content'>
            <p className='title mb-20' style={{ textAlign: 'center' }}>
              100% Money-Back{' '}
              <span className='text-gradient underlined-title'>Guarantee</span>
            </p>
            <p className='subtitle mb-20' style={{ textAlign: 'center' }}>
              My commitment to you is clear:
            </p>
            <p className='subtitle mb-20' style={{ textAlign: 'center' }}>
              If you don't absolutely love the program and see significant
              results, I'll gladly refund your entire investment.
            </p>
            <p className='subtitle mb-20' style={{ textAlign: 'center' }}>
              I'm very confident in my ability to help you achieve your fitness
              goals. Your success and satisfaction are my top priorities.
            </p>
            <Button
              className='landing-button'
              onClick={() => handleStripeCheckoutClick()}
              type='primary'
            >
              Get Started Now!
            </Button>
          </div>
        </div>
        <div ref={sectionsRefs['#home-section-6']} className='section white'>
          <div className='content'>
            <div>
              <div style={{ textAlign: 'center' }}>
                {!isTimerExpired ? (
                  <>
                    <h1 className='title mb-50'>
                      One-Time Payment for{' '}
                      <span className='text-gradient underlined-title'>
                        Lifetime Access!
                      </span>
                    </h1>
                  </>
                ) : (
                  <div className='grid grid justify-center mb-50'>
                    <p className='title mb-20' style={{ textAlign: 'center' }}>
                      Start Your{' '}
                      <span className='text-gradient underlined-title'>
                        Transformation, Today.
                      </span>
                    </p>
                    <p className='big-subtitle'>
                      The last program you'll ever need.
                    </p>
                  </div>
                )}
              </div>

              <div
                className='pricing-container mt-30'
                style={{ background: colorBasedOnGender }}
              >
                <img src={Mockup} alt='Mockup' />
                <div className='list-container mb-30'>
                  <div className='grid justify-center'>
                    <div className='pricing-items'>
                      <p
                        className='discounted-price'
                        style={{ color: isTimerExpired ? 'black' : 'black' }}
                      >
                        {isTimerExpired ? '$349.00' : '$249.00'}
                      </p>
                      {!isTimerExpired && (
                        <>
                          <div className='button'>Save $100</div>
                          <p className='actual-price'>$349.00</p>
                        </>
                      )}
                    </div>
                    <div className='mb-30' style={{ textAlign: 'center' }}>
                      <CountdownTimer
                        expiryTimestamp={parseInt(expiryTimestamp)}
                      />
                    </div>
                  </div>

                  <div className='mb-30'>
                    {pricingCardList.map((listItem) => (
                      <div
                        className='subtitle flex align-center mb-10'
                        style={{ fontWeight: '500' }}
                      >
                        <AiOutlineCheck
                          className='mr-10'
                          style={{
                            fontSize: '24px',
                            color: '#0665ff',
                            marginRight: '5px',
                          }}
                        />
                        <p>{listItem}</p>
                      </div>
                    ))}
                  </div>
                  <Button
                    onClick={() => handleStripeCheckoutClick()}
                    className='landing-button full-width-button'
                    type='primary'
                  >
                    Get Started Now!
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='section' style={{ background: colorBasedOnGender }}>
          <div>
            <div className='offer-content'>
              <h1 className='title mb-20'>
                This is a Limited Time{' '}
                <span className='text-gradient underlined-title'>Offer!</span>
              </h1>
              <p
                className='subtitle'
                style={{
                  maxWidth: '600px',
                  margin: '0 auto',
                  marginBottom: '20px',
                }}
              >
                As part of my online program launch, you’ll get a personalized
                transformation plan and unlimited lifetime access with a
                one-time payment. Hurry—pricing switches to monthly soon.
              </p>
              {!isTimerExpired && (
                <>
                  <p className='offer-warning'>This offer expires in</p>
                  <div className='mb-40'>
                    <CountdownTimer
                      expiryTimestamp={parseInt(expiryTimestamp)}
                    />
                  </div>
                </>
              )}
              <Button
                onClick={() => handleStripeCheckoutClick()}
                type='primary'
                className='landing-button mt-20'
              >
                Claim Your Spot!
              </Button>
            </div>
          </div>
        </div>
        <div
          ref={sectionsRefs['#home-section-6']}
          className='centered-container'
        >
          <div className='max-width-container'>
            {renderFaq(faqData.programFaq, null, true)}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProgramResultsPage;
