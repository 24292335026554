export const features = [
  {
    icon: '💪',
    title: 'Tailored Workouts and Nutrition',
    subtitle:
      "Upon sign up, I will tailor a personalized workout and nutrition plan based on your goals and preferences. It's not one-size-fits-all; it's customized for you.",
  },
  {
    icon: '📈',
    title: 'Exercise History Tracking',
    subtitle:
      'Beyond simply monitoring your progress, this tool empowers you to hold yourself accountable and push yourself for more.',
  },
  {
    icon: '🛒',
    title: 'Smart Shopping List',
    subtitle:
      'The smart shopping list generator calculates items and quantities in your plan, saving you time and effort. No more guesswork.',
  },
  {
    icon: '🍽️',
    title: 'Alternative Foods',
    subtitle:
      'Every food item in your plan includes alternative options, enabling you to seamlessly swap for your preferred choice.',
  },
  {
    icon: '🥘',
    title: 'Thousands of Recipes',
    subtitle:
      "Craving a recipe over your scheduled meal? Easily get recommended recipes matching your meal's nutritional profile with just a simple click.",
  },
  {
    icon: '📸',
    title: 'Progress Picture Comparison',
    subtitle:
      'Track and compare your physical transformation with the easy-to-use progress picture feature.',
  },
  {
    icon: '📚',
    title: 'Access To Workout, Nutrition, and Lifestyle Guides',
    subtitle:
      'Continuously expand your knowledge and skills with my ever-growing collection of comprehensive workout, nutrition, and lifestyle guides.',
  },
  {
    icon: '👥',
    title: 'Private Support & Accountability Group',
    subtitle:
      'Connect with a dedicated community and your coach to keep you on track until the end of your program and beyond.',
  },
  {
    icon: '🔗',
    title: 'Integrations',
    subtitle:
      'Seamlessly sync with Fitbit for advanced food tracking and Withings for accurate body composition data. Get of automatic updates for your weight, body fat, and nutritional needs as your body changes.',
  },
  {
    icon: '🚀',
    title: 'New Feature Releases',
    subtitle:
      "I'm dedicated to innovation. Get ready for constant releases of new features and improvements, ensuring your fitness journey stays fresh and exciting.",
  },
];
