import React from 'react';
import { Progress, Input, Tabs, Button } from 'antd';
import { capitalize } from '../../helpers/utils';
import { faqData } from '../../data/faq';
import { renderFaq } from '../Faq';
import { LeadForm } from '../Form';
import { IoIosArrowBack } from 'react-icons/io';
import './index.css';

export const MacronutrientCalculatorComponent = (props) => {
  const renderStat = (macro) => {
    return (
      <div>
        <p className='subtitle bold' style={{ fontSize: '26px', color: '' }}>
          {macro[0] === 'calories' ? macro[1] : `${macro[1]} g`}
        </p>
        <p style={{ fontSize: '14px', margin: 0 }}>{capitalize(macro[0])}</p>
      </div>
    );
  };

  const renderProgress = () => {
    // Filter the macros array to include only calories, protein, carbs, and fat
    let macros = Object.entries(props.results).filter(
      (r) =>
        r[0] === 'calories' ||
        r[0] === 'protein' ||
        r[0] === 'carbs' ||
        r[0] === 'fat'
    );
    // Find the total calories value
    const totalCalories = props.results.calories;

    const progressColors = {
      '0%': '#2761f1',
      '100%': '#ff00a2',
    };
    return (
      <div className='macros-progress'>
        {macros.map((macro) => {
          let percentage;
          if (macro[0] === 'calories') {
            percentage = 100;
          } else if (macro[0] === 'protein' || macro[0] === 'carbs') {
            percentage = ((macro[1] * 4) / totalCalories) * 100;
          } else if (macro[0] === 'fat') {
            percentage = ((macro[1] * 9) / totalCalories) * 100;
          }

          return (
            <div key={macro[0]} style={{ padding: '10px' }}>
              <Progress
                type='circle'
                strokeColor={progressColors}
                status='active'
                percent={percentage}
                format={() => renderStat(macro)}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const renderCustomMacrosInput = () => {
    if (props.macronutrientAnswers.isCustomMacros) {
      return (
        <div>
          <div className='custom-macros-input-container'>
            {Object.entries(props.macronutrientAnswers.customMacros).map(
              (macro) => (
                <Input
                  onChange={(e) =>
                    props.setCustomMacros(macro[0], Number(e.target.value))
                  }
                  value={props.macronutrientAnswers.customMacros[macro[0]]}
                  type='number'
                  addonAfter={`${capitalize(macro[0])} %`}
                  maxLength={2}
                />
              )
            )}
          </div>
          {props.percentageError ? (
            <p
              className='mb-20'
              style={{ textAlign: 'center', color: 'red', fontSize: '14px' }}
            >
              Macro percentages must equal 100%
            </p>
          ) : null}
        </div>
      );
    }
  };

  const items = [
    {
      key: 'balanced',
      label: `Balanced`,
      explanation:
        'Suitable for individuals seeking a well-rounded approach to nutrition, maintaining a balance between macronutrients (carbs, protein, fat) for overall health and well-being.',
    },
    {
      key: 'low-carb',
      label: `Low Carb`,
      explanation:
        'Ideal for people aiming to reduce their carbohydrate intake, which may be helpful for weight loss or managing conditions like diabetes.',
    },
    {
      key: 'high-carb',
      label: `High Carb`,
      explanation:
        'Beneficial for individuals engaged in intense physical activities or endurance training, as carbohydrates provide energy for performance.',
    },
    {
      key: 'high-protein',
      label: `High Protein`,
      explanation:
        'Recommended for those looking to support muscle building, recovery, and overall body composition improvements.',
    },
    {
      key: 'ketogenic',
      label: `Ketogenic`,
      explanation:
        'Suitable for individuals seeking to achieve ketosis, a metabolic state where the body burns fat for fuel, potentially aiding in weight loss and certain health conditions.',
    },
    {
      key: 'custom',
      label: `Custom`,
      explanation: 'For experienced individuals who want more personalization.',
    },
  ];

  const formInfo = {
    title: '🔥 Get A Customized Serving Guide Based On Your Results!',
    subtitle:
      'Access the full guide now, including your customized hand portion serving guide, and a bonus gift!',
    buttonText: 'Unlock Your Free Guide',
    url: '/api/sendgrid/send-macro-email',
    templateId: 'd-b2486d4983194680877e78cff466177b',
    tags: ['Subscriber', 'Tools', '52 Recipes Ebook'],
    subject: 'Your Macronutrient Calculator Report',
    source: 'macronutrients',
  };

  const style = {
    width: '100%',
    marginBottom: '50px',
  };

  return (
    <div className='questions-container'>
      {!props.showResult ? (
        <div className={`${props.showResult ? 'mb-50' : ''}`}>
          <div className='mb-30'>
            {/* {props.renderDropdown(
                props.questionOptions.unit.options,
                'Unit',
                'unit'
              )} */}
            <div className='two-col-input'>
              {props.renderDropdown(
                props.questionOptions.gender.options,
                'Gender',
                'gender'
              )}
              {props.renderInputField('Age', 'age')}
            </div>

            <div className='two-col-input'>
              {props.renderInputField('Weight', 'weight')}
              {props.renderDropdown(
                props.questionOptions.height.options,
                'Height',
                'height'
              )}
            </div>
            <div className='two-col-input'>
              {props.renderDropdown(
                props.questionOptions.activityFactor.options,
                'Activity Factor',
                'activityFactor'
              )}
              <div>
                {props.renderDropdown(
                  props.questionOptions.bmrFormula.options,
                  'Formula',
                  'bmrFormula'
                )}
                {props.macronutrientAnswers.bmrFormula === 'katch-mcardle'
                  ? props.renderInputField(
                      'Body Fat Percentage',
                      'bodyFatPercentage'
                    )
                  : null}
              </div>
            </div>

            {props.renderDropdown(
              props.questionOptions.goal.options,
              'Goal',
              'goal'
            )}
          </div>

          <Button
            loading={props.isLoading}
            onClick={() => props.showResults()}
            size='large'
            type='primary'
          >
            Get My Results
          </Button>
        </div>
      ) : (
        <div className='landing-page'>
          <div className='results-container'>
            <div className='grid justify-center'>
              <p className='title text-center mb-10'>
                🎉 Congrats, Your Results Are In!
              </p>
              <p
                className='big-subtitle mb-10'
                style={{
                  width: '100%',
                  maxWidth: '600px',
                  textAlign: 'center',
                }}
              >
                Select your dietary preference and when you are ready, enter
                your name an email to get a customized serving guide based on
                your results.
              </p>
              <Button
                type='secondary'
                className='landing-button gradient flex align-center mb-50 '
                onClick={() => props.handleShowResult(false)}
              >
                <IoIosArrowBack />
                Calculate Again
              </Button>
            </div>

            <div className='two-column align-center'>
              {renderProgress()}
              <div className='flex'>
                <Tabs
                  defaultActiveKey='balanced'
                  items={items}
                  onChange={(value) => props.setMacroPreference(value)}
                  animated={false}
                  tabPosition={'left'}
                  size={'small'}
                  type={'card'}
                />
                <div>
                  <p className='small-title mb-10'>
                    {capitalize(props.macronutrientAnswers.macroPreference)}
                  </p>
                  <p className='subtitle-text mb-10'>
                    {
                      items.find(
                        (item) =>
                          item.key ===
                          props.macronutrientAnswers.macroPreference
                      ).explanation
                    }
                  </p>
                  {renderCustomMacrosInput()}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {props.showResult && (
        <div className='mb-100'>
          <LeadForm
            goal={props.macronutrientAnswers.goal}
            gender={props.macronutrientAnswers.gender}
            results={props.results}
            macroPreference={props.macronutrientAnswers.macroPreference}
            percentageError={props.percentageError}
            formInfo={formInfo}
            step={true}
          />
        </div>
      )}
      {props.showResult && renderFaq(faqData.macronutrientCalculator, style)}
    </div>
  );
};
